import NextLink from 'next/link';

import { Container, Grid, SectionText } from '@/atoms';
import { CardCity } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeWhereWeAreProps } from './types';

/**
 * WhereWeAre
 */
export const WhereWeAre: FunctionComponent<TypeWhereWeAreProps> = ({
  cities,
  backgroundColorBottom,
  backgroundColorBottomMobile,
  backgroundColorTop,
  backgroundColorTopMobile,
  caption,
  cutLevel,
  cutLevelMobile,
  desc,
  index,
  textColor,
  title,
  id,
}: TypeWhereWeAreProps) => (
  <Container
    id={id}
    index={index}
    backgroundColorBottom={backgroundColorBottom}
    backgroundColorTopMobile={backgroundColorTopMobile}
    backgroundColorTop={backgroundColorTop}
    backgroundColorBottomMobile={backgroundColorBottomMobile}
    cutLevel={cutLevel === null ? 50 : cutLevel}
    cutLevelMobile={cutLevelMobile === null ? 50 : cutLevel}
    className="flex flex-col gap-10"
  >
    <SectionText
      title={title}
      desc={desc}
      direction="row"
      textAlign="start"
      variant={textColor}
      caption={caption}
    />
    <Grid xs={2} md={3} outline={false} className="gap-4 md:gap-8">
      {cities.map(({ image, name, href }, index) => (
        <Grid.Item key={index}>
          <NextLink href={href} prefetch={false}>
            <a>
              <CardCity className="h-40 md:h-52" image={image} name={name} />
            </a>
          </NextLink>
        </Grid.Item>
      ))}
    </Grid>
  </Container>
);

WhereWeAre.displayName = 'WhereWeAre';
