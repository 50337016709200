import Image from 'next/image';

import type { FunctionComponent } from 'react';
import type { TypeCardCityProps } from './types';

/**
 * CardCity
 */
export const CardCity: FunctionComponent<TypeCardCityProps> = ({
  className = '',
  image,
  name,
}: TypeCardCityProps) => (
  <figure
    className={`group relative grid select-none place-items-center overflow-hidden rounded-md p-1 md:p-2 ${className}`}
  >
    <Image
      src={image.src}
      alt={image.alt || name}
      layout="fill"
      objectFit="cover"
      className="transition-transform group-hover:scale-110"
    />

    <div className="absolute inset-0 bg-black opacity-50" />

    <figcaption className="text-heading text-heading--h4 relative z-10 line-clamp-1 max-w-full text-white">
      {name}
    </figcaption>
  </figure>
);

CardCity.displayName = 'CardCity';
